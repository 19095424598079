html {
  background: #efefef;
}
body {
  font-weight: 500;
}
.app-bar {
  background-color: #242b52 !important;
}
.fixed-container {
  position: fixed;
  width: 100%;
  background: #f3f3f3;
  z-index: 1;
}
.ad_container {
  height: 70px;
  width: 100%;
  background: #842f2f;
}
.tabs {
  list-style: none;
  display: inline-flex;
  width: 100%;
  padding-left: 0;
  margin: 0;
}
.tabs > li {
  display: inline-block;
  width: 100%;
}
.tabs > li a {
  line-height: 51px;
  text-align: center;
  display: inline-block;
  width: 100%;
}
.tabs li.active a {
  color: #3ea75e;
  border-bottom: 2px solid #3ea75e;
}
.font-weight-500 {
  font-weight: 500;
}
.center-align {
  text-align: center;
}
.dot {
  height: 5px;
  width: 5px;
  background: #888686;
  display: inline-block;
  border-radius: 50%;
}
.text {
  color: #464646;
}
.mini-text {
  color: #464646;
  font-size: 14px;
  line-height: 23px;
}
.midium-text {
  color: #464646;
  font-size: 16px;
  line-height: 23px;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
.link {
  display: inline-block;
  color: #2b8e26;
  cursor: pointer;
}
.payment_pending {
  color: #c54a4a;
}
.payment_paid {
  color: #3ca229;
}
.booking_action {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.booking_action.show {
  height: auto;
}
.consult {
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  display: flex;
  align-items: center;
  height: 22px;
}
.consult_confirmed {
  background: #a0515185;
}
.consult_consulting {
  background: #b32c5ef0;
}
.consult_consulted {
  background: #426f2f;
}
.consult_patient_cancelled {
  background: #af7e0fd9;
  font-size: 11px;
  color: white;
}
.consult_doctor_cancelled {
  background: #e41212d9;
}
.dialogbody {
  padding: 12px;
}
.pull-right {
  float: right;
}
.row {
  width: 100%;
  min-height: 28px;
}
.autofilled-tag {
  width: 70px;
  margin-top: -1px;
  height: 20px;
  background-color: #c10909;
  transform: rotate(30deg);
  position: absolute;
  right: 0px;
  font-size: 11px;
  color: white;
  /* border-radius: 2px; */
  text-align: center;
  font-weight: 500;
  border-top-left-radius: 43px;
  border-top-right-radius: 45px;
}

.booking-form {
  padding-left: 20px;
  padding-right: 30px;
}
.booking-page {
  width: 100%;
  overflow-x: hidden;
}
.booking-container {
  position: fixed;
  top: 8%;
  z-index: 9;
  background: white;
  height: 92%;
  width: 100%;
  transform: translate(100%, 0px);
  transition: transform 100ms;
  animation-name: translate;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}
@keyframes translate {
  0% {
    transform: translate(100%, 0px);
  }
  100% {
    transform: translate(0%, 0px);
  }
}
.toast {
  position: fixed;
  width: 100%;
  top: 100%;
  animation-name: toastanimation;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background: #252222f5;
  z-index: 99;
  color: white;
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
}
@keyframes toastanimation {
  0% {
    top: 100%;
  }
  25% {
    top: 90%;
  }
  90% {
    top: 90%;
  }
  100% {
    top: 100%;
  }
}
.circle_check {
  height: 80px;
  width: 80px;
  border: 3px solid white;
  font-size: 66px;
  color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

/*multiple payments css*/
.payment-types {
  border: 1px solid;
  display: inline-block;
  line-height: 27px;
  border-radius: 10px;
  border-color: #3ea75e;
  font-size: 13px;
}
.payment-types > .payment-lable {
  display: inline-flex;
  justify-content: center;
  border-left: 1px solid;
  border-color: inherit;
  padding-left: 5px;
  padding-right: 5px;
  color: #464646;
  cursor: pointer;
}
.payment-lable.active {
  background: #3ea75e;
  color: white;
}
.payment-types > .payment-lable:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  border-left: unset;
}
.payment-types > .payment-lable:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.payment-bar {
  height: 10px;
  width: 100px;
  display: inline-flex;
  background: #c7c0c09c;
  border-radius: 10px;
}
.payment-bar > .fill-bar {
  display: inline-block;
  height: 100%;
  background: #58a54a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.filter-button {
  text-align: center;
}
.filter-button > button {
  padding: 0;
}
.filter-button > button > span {
  height: 39px;
}
.filter-button i {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  background: #44a763;
  background: white;
  color: #923232;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
}
.filter-button > button:focus i {
  background: #71bf89;
  background: #ca7b7b;
  color: white;
}
.filter-option {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}
.filter-option > div {
  margin-left: auto;
}
.filter-group {
  background: #c5bdbd;
  margin-top: 4px;
  margin-bottom: 5px;
  padding-left: 10px;
  font-weight: 500;
}
.searchbox-container input[type="text"] {
  width: 100%;
  height: 36px;
  margin-top: 3px;
  border: none;
  outline: none;
  margin-left: 20px;
  border-radius: 3px;
  margin-right: 20px;
  padding-left: 34px;
  font-size: 18px;
}
.searchbox-container .fa-search {
  position: absolute;
  left: 31px;
  top: 11px;
}
/*buttons style*/
.round-button {
  display: inline-flex;
  width: 100%;
  border-radius: 20px;
  /* background: #C5C8D4; */
  font-weight: 500;
  line-height: 40px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
/*input types*/
.textarea {
  width: 100%;
  padding: 5px 10px;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  min-height: 80px;
  border-radius: 4px;
}
.input-text {
  min-height: 40px;
  outline: none;
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  padding: 0px 9px;
}
.input-text[type="number"] {
  letter-spacing: 1px;
}
.request {
  text-align: center;
  align-items: center;
  border-radius: 50px;
  margin-left: auto;
  width: 85px;
  color: white;
  padding: 5px;
}
.request__requested {
  background-color: #cbcccef5;
}
.request__rejected {
  background-color: #f52c1df5;
}
.request__accepted {
  background-color: #e5508f;
}
.btn__disabled {
  cursor: not-allowed;
}
